import React from "react"
import { Layout } from "../components/common"
import Literature from "../components/Literature"
import { Helmet } from "react-helmet"
const literature = () => {
  return (
    <Layout>
      <Helmet>
        <title>Literature</title>
      </Helmet>
      <Literature />
    </Layout>
  )
}

export default literature
